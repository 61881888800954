import { debounce, DebouncedFunc } from "lodash-es";
import { Target } from "@vytant/stimulus-decorators";

import ApplicationController from "./application_controller";
import DropdownMenuController from "./dropdown_menu_controller";

import { requestSubmit } from "../helpers/dom";
import Stimulus from "../helpers/stimulus";

export default class InstantSearchController extends ApplicationController {
  @Target formTarget!: HTMLFormElement;
  @Target resultsMenuTarget!: HTMLElement;

  debouncedSearch!: DebouncedFunc<() => void>;

  connect() {
    this.debouncedSearch = debounce(() => requestSubmit(this.formTarget), 300, { leading: true });
  }

  search(event: StimulusEvent) {
    const input = event.currentTarget as HTMLInputElement;

    if (input.value === "") {
      this.hideResultsMenu();
    } else {
      this.showResultsMenu(this.formTarget);
      this.debouncedSearch();
    }
  }

  hideResultsMenu() {
    Stimulus.getController(this.resultsMenuTarget, DropdownMenuController)!.hide();
  }

  showResultsMenu(anchor: HTMLElement) {
    Stimulus.getController(this.resultsMenuTarget, DropdownMenuController)!.show(anchor, { placement: "bottom" });
  }
}
