import { Target, Value } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";

export default class RecaptchaController extends ApplicationController {
  @Target tokenTarget!: HTMLInputElement;
  @Target actionTarget!: HTMLInputElement;
  @Value(String) sitekeyValue!: string;

  retrieveToken(event: StimulusEvent) {
    const promise = new Promise<void>((resolve) => {
      grecaptcha.enterprise.ready(async () => {
        this.tokenTarget.value = await grecaptcha.enterprise.execute(this.sitekeyValue, {
          action: this.actionTarget.value,
        });
        resolve();
      });
    });
    event.detail.promises.push(promise);
  }
}
