import { Target, Targets, Value } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import { addClass, removeClass, show, hide } from "../helpers/dom";

const highlightModeClass = "term-map--highlight-mode";
const highlightCellClass = "term-map__cell--highlight";

export default class TermMapController extends ApplicationController {
  @Target nextPageButtonTarget!: HTMLButtonElement;
  @Target previousPageButtonTarget!: HTMLButtonElement;
  @Targets cellTargets!: Array<Proppable<HTMLElement, { page: number; row: number; column: number }>>;
  @Value(Number) pageValue!: number;

  nextPage() {
    this.pageValue += 1;
  }

  previousPage() {
    this.pageValue -= 1;
  }

  resetHighlight() {
    this.element.classList.remove(highlightModeClass);
  }

  highlightCrosshair(event: StimulusEvent<{ row: number; column: number }>) {
    const { row, column } = event.params;
    this.element.classList.add(highlightModeClass);
    removeClass(this.currentCells, highlightCellClass);
    addClass(this.propSelectorAll("row", row), highlightCellClass);
    addClass(this.propSelectorAll("column", column), highlightCellClass);
  }

  pageValueChanged() {
    hide(this.cellTargets);
    show(this.currentCells);
    this.previousPageButtonTarget.disabled = !this.propSelectorAll("page", this.pageValue - 1).length;
    this.nextPageButtonTarget.disabled = !this.propSelectorAll("page", this.pageValue + 1).length;
  }

  get currentCells() {
    return [...this.propSelectorAll("column", -1), ...this.propSelectorAll("page", this.pageValue)];
  }
}
