import { Value } from "@vytant/stimulus-decorators";
import ContextError from "../helpers/error";
import ApplicationController from "./application_controller";
import handleError from "../helpers/handle_error";
import { appendParamsToUrl } from "../helpers/util";

export default class XhrController extends ApplicationController {
  @Value(String) urlValue!: string;
  @Value(String) methodValue!: string;
  @Value(String) confirmValue!: string;
  @Value(Boolean) fetchOnConnectValue!: boolean;
  @Value(Number) fetchDelayValue!: number;
  @Value(Object) paramsValue!: Record<string, any>;

  connect() {
    if (this.fetchOnConnectValue) {
      setTimeout(() => {
        this.fetch();
      }, this.fetchDelayValue);
    }
  }

  async fetch() {
    if (this.confirmValue.length) {
      if (!window.confirm(this.confirmValue)) {
        return;
      }
    }

    const url = new URL(this.urlValue, window.location.origin);
    const fetchOptions: RequestInit = {
      // PATCH must be in all caps
      // https://github.com/github/fetch/issues/254
      method: this.methodValue.toUpperCase(),
      headers: this.requestHeaders,
      credentials: "same-origin",
    };

    if (fetchOptions.method === "GET") {
      appendParamsToUrl(url, this.requestData);
    } else {
      fetchOptions.body = this.requestData;
    }

    this.dispatch("requesting");

    const response = await fetch(url, fetchOptions);
    const { status, headers } = response;
    const text = await response.text();

    this.dispatch("response");

    try {
      const contentType = headers.get("content-type");
      if (contentType == null || !contentType.includes("text/html")) {
        throw new ContextError(`Unexpected content-type: ${contentType}, status: ${status}`, { responseBody: text });
      }
      if (!(status >= 200 && status <= 399)) {
        throw new ContextError(`Unexpected fetch status: ${status}`, { responseBody: text });
      } else if (status === 309) {
        const location = headers.get("XHR-Location");
        this.redirect(location);
        return; // redirecting short-circuits js execution
      }
      // TODO: Once we don't need dispatchXhrResponse in any other controller,
      // we can move the code here and use `this.dispatch` to prepend all Xhr response events with `xhr:`
      // we also don't need to pass status
      this.dispatchXhrResponse({ html: text, status });
      this.dispatch("success");
    } catch (e: any) {
      this.dispatch("error");
      handleError(e);
    }
    this.dispatch("complete");
  }

  redirect(location: string | null) {
    if (location) {
      window.location.assign(location);
    } else {
      window.location.reload();
    }
  }

  get requestHeaders() {
    const headers = new Headers();
    // Use default Content-Type (form-urlencoded for xhr, multipart form when body is FormData)
    // https://github.com/github/fetch/issues/505
    headers.set("X-CSRF-Token", document.querySelector<HTMLMetaElement>("meta[name=csrf-token]")!.content);
    headers.set("X-Requested-With", "XMLHttpRequest"); // enables calling `request.xhr?` in Rails
    return headers;
  }

  get requestData() {
    let data: URLSearchParams | FormData = new URLSearchParams();
    if (this.element instanceof HTMLFormElement) {
      data = new FormData(this.element);
    } else if (
      this.element instanceof HTMLSelectElement ||
      (this.element instanceof HTMLInputElement && (this.element.type !== "checkbox" || this.element.checked))
    ) {
      data.append(this.element.name, this.element.value);
    }
    // TODO: Stop using paramsValue and use forms instead
    Object.entries(this.paramsValue).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => data.append(`${key}[]`, v));
      } else {
        data.append(key, value);
      }
    });
    return data;
  }
}
