import * as Sentry from "@sentry/browser";
import ToastController from "../controllers/toast_controller";
import Stimulus from "./stimulus";

export const genericErrorMessage = "Oops, Something went wrong! Please refresh and try again.";

export type HandleErrorOptions = { level?: Sentry.SeverityLevel; displayMessage?: string | false };

export default function handleError(e: Error, opts: HandleErrorOptions = {}) {
  const { level = "debug", displayMessage = genericErrorMessage } = opts;
  Sentry.captureException(e, { level, extra: { displayMessage } });
  if (displayMessage) {
    Stimulus.getController(document.body, ToastController)!.alert(displayMessage);
  }
}
