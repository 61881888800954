import { Targets } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import Stimulus from "../helpers/stimulus";
import TabPaneController from "./tab_pane_controller";

export default class TabController extends ApplicationController {
  @Targets navButtonTargets!: Array<HTMLElement>;
  @Targets paneTargets!: Array<Proppable<HTMLElement, { id: number }>>;

  show(event: StimulusEvent<{ paneId: number }>) {
    const { paneId } = event.params;
    this.navButtonTargets.forEach((navButton) => {
      if (navButton !== event.currentTarget) {
        navButton.classList.remove("active");
      } else {
        navButton.classList.add("active");
      }
    });
    this.paneTargets.forEach((pane) => {
      const active = this.getProp(pane, "id") === paneId;
      const tabPane = Stimulus.getController(pane, TabPaneController)!;
      tabPane.activeValue = active;
    });
  }
}
