import Highcharts, { Options } from "highcharts";
import Color from "color";
import { getCssProperty } from "./util";

export default function setupHighcharts() {
  // colors must be converted to Highcharts-supported formats for certain features (fillOpacity, hover states, etc.) to work
  // https://www.highcharts.com/docs/chart-design-and-style/colors#solid-colors
  // Color.parsers not public interface
  // https://github.com/highcharts/highcharts/blob/3f42ce5131331208cba1eff39855c8be91ce2412/ts/Core/Color/Color.ts
  // @ts-ignore
  Highcharts.Color.parsers.push({
    regex: /^hsl.+$/,
    parse(result: RegExpExecArray) {
      const rgb = Color(result[0]).rgb().round().array();
      return [...rgb, 1]; // returns rgba to Highcharts
    },
  });

  const options: Options = {
    chart: {
      backgroundColor: "transparent",
      styledMode: false, // doesn't play well with custom series colors
      style: { fontFamily: getCssProperty("--bs-body-font-family") },
    },
    colors: [getCssProperty("--bs-blue"), getCssProperty("--bs-orange")],
    title: {
      text: "",
    },
    legend: {
      enabled: false,
      itemStyle: { color: getCssProperty("--bs-body-color"), fontWeight: "normal" },
    },
    tooltip: {
      borderColor: getCssProperty("--bs-border-color"),
      hideDelay: 0,
      outside: true, // allow tooltips to overflow chart container
      useHTML: true,
      shared: true,
      headerFormat: '<div class="mb-2 fw-600">{point.key}</div>',
      pointFormat:
        '<div class="hstack gap-2 mb-1 justify-content-between"><div><span style="color: {series.color}">●</span> {series.name}</div><div>{point.y}</div></div>',
      style: {
        color: getCssProperty("--bs-body-color"),
        zIndex: 1080, // same as $zindex-tooltip, ensures tooltips show over dropdowns, modals, etc.
      },
    },
    xAxis: {
      crosshair: {
        color: getCssProperty("--bs-gray-200"),
      },
      lineColor: getCssProperty("--bs-gray-200"),
      gridLineColor: getCssProperty("--bs-gray-200"),
      tickLength: 0,
      labels: {
        autoRotation: [], // disable label rotation
        style: {
          color: getCssProperty("--bs-gray-600"),
          fontSize: "12px",
        },
      },
    },
    yAxis: {
      allowDecimals: false,
      lineColor: getCssProperty("--bs-gray-200"),
      gridLineColor: getCssProperty("--bs-gray-200"),
      opposite: true,
      min: 0,
      labels: {
        style: {
          color: getCssProperty("--bs-gray-600"),
          fontSize: "12px",
        },
        y: 0,
      },
      title: {
        text: null,
      },
    },
    plotOptions: {
      series: {
        animation: false, // for performance reasons
      },
    },
    lang: {
      thousandsSep: ",",
    },
    credits: {
      enabled: false,
    },
  };
  Highcharts.setOptions(options);
}
