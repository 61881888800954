import { Target } from "@vytant/stimulus-decorators";
import Modal from "bootstrap/js/dist/modal";
import Stimulus from "../helpers/stimulus";
import { autofocus, hide } from "../helpers/dom";
import ApplicationController from "./application_controller";
import DropdownController from "./dropdown_controller";
import TooltipController from "./tooltip_controller";

export default class ModalController extends ApplicationController {
  @Target containerTarget!: HTMLElement;
  @Target spinnerTarget!: HTMLElement;

  loading(event: StimulusEvent) {
    event.preventDefault();
    // hide instead of replacing contents to avoid elements with multiple event listeners removing itself
    // e.g. an element inside the modal with 'modal#loading xhr#fetch' actions would remove itself before xhr#fetch fires
    hide(this.containerTarget.children);
    this.containerTarget.insertAdjacentHTML("afterbegin", this.spinnerTarget.innerHTML);
    this.show();
  }

  show() {
    this.modal.show();
    // prevents dropdowns, tooltips from showing over modals
    Stimulus.getController(document.body, DropdownController)?.hideAll();
    Stimulus.getController(document.body, TooltipController)?.hide();
  }

  insert(event: StimulusEvent) {
    this.containerTarget.innerHTML = event.detail.html;
    autofocus(this.containerTarget);
  }

  hide() {
    this.modal.hide();
  }

  get modal() {
    return Modal.getOrCreateInstance(this.containerTarget);
  }
}
