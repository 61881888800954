import { Target, Value } from "@vytant/stimulus-decorators";
import { fromPairs } from "lodash-es";
import ApplicationController from "./application_controller";
import { guardInstance } from "../helpers/util";
import Stimulus from "../helpers/stimulus";
import SortableItemController from "./sortable_item_controller";

type Sort = [string, "asc" | "desc"];

function sortableItemController(ele: HTMLElement) {
  return Stimulus.getController(ele, SortableItemController);
}

export default class SortableController extends ApplicationController {
  @Target selectTarget!: HTMLInputElement;
  @Target listTarget!: HTMLElement;
  readonly hasListTarget!: boolean;
  @Value(Array) tiebreakersValue!: Array<Sort>;

  connect() {
    Promise.resolve().then(() => {
      this.sort();
    });
  }

  sort() {
    const sorts = [this.selectTarget.value.split(",") as Sort, ...this.tiebreakersValue];
    this.sortBy(sorts);
  }

  sortBy(sorts: Array<Sort>) {
    if (!this.hasListTarget) return;
    const sortsObj = fromPairs(sorts);
    const sortKeys = Object.keys(sortsObj);
    Array.from(this.listTarget.children)
      .filter(guardInstance(HTMLElement))
      .filter((ele) => sortableItemController(ele))
      .sort((item1, item2) => {
        const itemValues1 = sortableItemController(item1)!.values;
        const itemValues2 = sortableItemController(item2)!.values;
        const decideBy = sortKeys.find((key) => itemValues1[key] !== itemValues2[key]) || sortKeys[0];
        const direction = sortsObj[decideBy] === "asc" ? 1 : -1;
        const numericalResult = itemValues1[decideBy] - itemValues2[decideBy];
        if (Number.isNaN(numericalResult)) {
          return itemValues1[decideBy].localeCompare(itemValues2[decideBy]) * direction;
        }
        return numericalResult * direction;
      })
      .forEach((ele) => {
        this.listTarget.appendChild(ele);
      });
  }
}
