import { Controller } from "@hotwired/stimulus";
import { camelCase } from "lodash-es";
import { TypedController } from "@vytant/stimulus-decorators";
import { jsonCast } from "../helpers/util";
import { XhrResponse } from "../helpers/xhr";
import { templatizeHtml } from "../helpers/dom";

// TODO: Remove usage of stimulus-decorators with our own?
// Allow for has...Target as automatically inferred type and for correct defaults
@TypedController
export default class ApplicationController<T extends Element = HTMLElement> extends Controller<T> {
  getProp<E extends Proppable<HTMLElement, any>, K extends keyof E["props"]>(target: E, k: Extract<K, string>) {
    const dataKey = camelCase(`${this.identifier}-${k}-prop`);
    return jsonCast(target.dataset[dataKey]) as E["props"][K];
  }

  setProps(target: HTMLElement, props: { [key: string]: any }) {
    Object.keys(props).forEach((k) => {
      const dataKey = camelCase(`${this.identifier}-${k}-prop`);
      target.dataset[dataKey] = props[k];
    });
  }

  propSelectorAll(prop: string, value: { toString(): string }): NodeListOf<HTMLElement> {
    return this.element.querySelectorAll(`[data-${this.identifier}-${prop}-prop="${value.toString()}"]`);
  }

  propSelector(prop: string, value: { toString(): string }): HTMLElement {
    return this.propSelectorAll(prop, value)[0];
  }

  // TODO: this is no longer needed once we rely on XhrController for all Xhr requests
  dispatchXhrResponse(xhrResponse: XhrResponse) {
    const { html } = xhrResponse;
    const template = templatizeHtml(html!);
    template.content.querySelectorAll<HTMLTemplateElement>("template[data-event]").forEach((child) => {
      const { event } = child.dataset;
      this.dispatch(event!, { prefix: "", bubbles: true, detail: { html: child.innerHTML, data: child.dataset } });
    });
    return xhrResponse;
  }
}
