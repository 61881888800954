import { Extension } from "@tiptap/core";
import { Plugin, PluginKey, TextSelection, Transaction } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

const pluginKey = new PluginKey("blurredSelection");

class SelectionState {
  decorationSet: DecorationSet;

  constructor(decorationSet: DecorationSet) {
    this.decorationSet = decorationSet;
  }

  apply(tr: Transaction) {
    const { doc, selection } = tr;
    if (tr.getMeta("blur")) {
      // known issue: https://github.com/ProseMirror/prosemirror/issues/1097
      const selectionDecoration = Decoration.inline(selection.from, selection.to, {
        style: "background-color: var(--bs-gray-300)",
      });
      return new SelectionState(DecorationSet.create(doc, [selectionDecoration]));
    }
    if (tr.getMeta("focus")) {
      return new SelectionState(DecorationSet.create(doc, []));
    }
    return this;
  }
}

export default Extension.create({
  name: "blurredSelection",
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: pluginKey,
        state: {
          init(_, { doc }) {
            return new SelectionState(DecorationSet.create(doc, []));
          },
          apply(tr, prevState) {
            return prevState.apply(tr);
          },
        },
        props: {
          handleDOMEvents: {
            mousedown(view, event) {
              // setting decoration on `focus` breaks default mousedown behavior, so we re-implement it ourselves
              const { state } = view;
              const { tr } = state;
              const hasSelectionDecoration = this.getState(state)?.decorationSet.find().length;
              const eventPos = view.posAtCoords({ left: event.clientX, top: event.clientY })?.pos;
              if (hasSelectionDecoration && eventPos) {
                const resolvedEventPos = state.doc.resolve(eventPos);
                view.dispatch(tr.setSelection(new TextSelection(resolvedEventPos, resolvedEventPos)));
              }
            },
          },
          decorations(state) {
            return this.getState(state)?.decorationSet;
          },
        },
      }),
    ];
  },
});
