import { Target, Targets } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import { show, hide, addClass, removeClass, setProperties } from "../helpers/dom";
import XhrController from "./xhr_controller";
import Stimulus from "../helpers/stimulus";

export default class MultiselectController extends ApplicationController {
  @Targets checkboxAllTargets!: Array<HTMLInputElement>;
  @Targets checkboxTargets!: Array<HTMLInputElement>;
  @Targets selectedHideTargets!: Array<HTMLElement>;
  @Targets selectedShowTargets!: Array<HTMLElement>;
  @Targets selectedInvisibleTargets!: Array<HTMLElement>;
  @Targets selectedVisibleTargets!: Array<HTMLElement>;
  @Targets selectedInputTargets!: Array<HTMLInputElement>;
  @Target selectStatusTarget!: HTMLElement;
  readonly hasSelectStatusTarget!: boolean;

  connect() {
    this.resolveSelectState();
  }

  addSelectedXhrParams(event: StimulusEvent<{ paramKey: string }>) {
    const xhrController = Stimulus.getController(event.currentTarget, XhrController)!;
    const selectedValues = this.checkboxTargets.filter((ele) => ele.checked).map((ele) => ele.value);
    xhrController.paramsValue = { ...xhrController.paramsValue, [event.params.paramKey]: selectedValues };
  }

  check() {
    this.resolveSelectState();
  }

  checkAll(event: StimulusEvent) {
    this.checkboxTargets.forEach((ele) => {
      ele.checked = (event.currentTarget as HTMLInputElement).checked;
    });
    this.resolveSelectState();
  }

  resolveSelectState() {
    const selectedCount = this.checkboxTargets.filter((ele) => ele.checked).length;
    if (selectedCount) {
      if (this.hasSelectStatusTarget) {
        this.selectStatusTarget.innerHTML = `${selectedCount} selected`;
      }
      show(this.selectedShowTargets);
      hide(this.selectedHideTargets);
      removeClass(this.selectedVisibleTargets, "invisible");
      addClass(this.selectedInvisibleTargets, "invisible");
      if (this.checkboxTargets.every((ele) => ele.checked)) {
        setProperties(this.checkboxAllTargets, { checked: true, indeterminate: false });
      } else {
        setProperties(this.checkboxAllTargets, { checked: false, indeterminate: true });
      }
    } else {
      hide(this.selectedShowTargets);
      show(this.selectedHideTargets);
      addClass(this.selectedVisibleTargets, "invisible");
      removeClass(this.selectedInvisibleTargets, "invisible");
      setProperties(this.checkboxAllTargets, { checked: false, indeterminate: false });
    }
  }
}
