import Highcharts from "highcharts";
import { Target } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import Stimulus from "../helpers/stimulus";
import HighchartsController from "./highcharts_controller";

export default class ChartWithAnnotationsController extends ApplicationController {
  @Target annotationsTarget!: HTMLElement;
  @Target chartTarget!: HTMLElement;

  alignAnnotations() {
    const chart = Stimulus.getController(this.chartTarget, HighchartsController)?.chart;

    if (!chart) return;

    this.annotationsTarget.style.left = `${chart.plotLeft}px`;
    this.annotationsTarget.style.width = `${chart.plotWidth}px`;

    Highcharts.addEvent(chart, "render", (event: Event) => {
      if (!event.target) return;

      const { plotLeft, plotWidth } = event.target as EventTarget & { plotLeft: number; plotWidth: number };

      this.annotationsTarget.style.left = `${plotLeft}px`;
      this.annotationsTarget.style.width = `${plotWidth}px`;
    });
  }
}
