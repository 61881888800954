import { Targets } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";

export default class LineNumberedFieldsController extends ApplicationController {
  @Targets numbersTargets!: Array<HTMLElement>;
  @Targets textareaTargets!: Array<HTMLTextAreaElement>;

  connect() {
    this.setLines();
  }

  setLines() {
    const textareaLineCounts = this.textareaTargets.map((ele) => (ele.value.match(/\n/g) || []).length + 1);
    const numLines = Math.max(...textareaLineCounts);
    const numbersHtml = Array(numLines)
      .fill(undefined)
      .map((_, i) => i + 1)
      .join("<br>");
    this.numbersTargets.forEach((ele) => {
      ele.innerHTML = numbersHtml;
    });
  }
}
