import ClipboardJS from "clipboard";
import { Target } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import { templatizeHtml } from "../helpers/dom";
import ToastController from "./toast_controller";
import Stimulus from "../helpers/stimulus";

export default class ClipboardController extends ApplicationController {
  @Target sourceTarget!: HTMLTemplateElement;

  copy() {
    // elements inside `<template>` or `display:none` are not copyable
    const container = templatizeHtml('<div style="position:absolute;opacity:0;"></div>').content.firstElementChild!;
    const clone = this.sourceTarget.content.cloneNode(true);
    container.appendChild(clone);
    document.body.appendChild(container);
    ClipboardJS.copy(container);
    document.body.removeChild(container);
    window.getSelection()?.removeAllRanges();
    Stimulus.getController(document.body, ToastController)!.notice("Copied!");
  }
}
