import { Target, Value } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import { autofocus, cloneElement } from "../helpers/dom";

export default class TabPaneController extends ApplicationController {
  @Target templateTarget!: HTMLTemplateElement;
  readonly hasTemplateTarget!: boolean;
  @Value(Boolean) activeValue!: boolean;

  activeValueChanged() {
    if (this.activeValue) {
      if (this.hasTemplateTarget) {
        const { content } = cloneElement(this.templateTarget);
        this.element.innerHTML = "";
        this.element.appendChild(content);
      }
      this.element.classList.add("active");
      autofocus(this.element);
    } else {
      this.element.classList.remove("active");
    }
  }
}
