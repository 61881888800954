import { Target, Value } from "@vytant/stimulus-decorators";
import { show, hide } from "../helpers/dom";
import ApplicationController from "./application_controller";

export default class SubmitController extends ApplicationController<HTMLButtonElement> {
  @Value(Boolean) isSubmittingValue!: boolean;
  @Target submittingIconTarget!: HTMLElement;

  isSubmittingValueChanged() {
    if (this.isSubmittingValue) {
      this.element.disabled = true;
      show([this.submittingIconTarget]);
    } else {
      this.element.disabled = false;
      hide([this.submittingIconTarget]);
    }
  }
}
