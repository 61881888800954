import { Value } from "@vytant/stimulus-decorators";
import { loadStripe } from "@stripe/stripe-js/pure";
import * as Sentry from "@sentry/browser";
import ApplicationController from "./application_controller";
import ToastController from "./toast_controller";
import Stimulus from "../helpers/stimulus";
import { StripeEmbeddedCheckout } from "@stripe/stripe-js/dist/stripe-js/embedded-checkout";

export default class StripeEmbeddedFormController extends ApplicationController {
  @Value(String) clientSecretValue!: string;
  checkout!: StripeEmbeddedCheckout;

  async connect() {
    // Using loadStripe ensures we do not load Stripe on all pages
    const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

    if (stripe != null) {
      this.checkout = await stripe.initEmbeddedCheckout({ clientSecret: this.clientSecretValue });
      this.checkout.mount(this.element);
    } else {
      Stimulus.getController(document.body, ToastController)!.alert(
        "There were some errors loading the payment checkout. Please try to reload the page. If this doesn't help, please contact support",
      );
      // We unfortunately do not have any more info at this point, but lets at least log it to Sentry:
      Sentry.captureMessage("Could not load Stripe");
    }
  }

  disconnect() {
    this.checkout.destroy();
  }
}
