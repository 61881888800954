import {
  arrow,
  autoUpdate,
  computePosition,
  flip,
  offset,
  Placement,
  ReferenceElement,
  shift,
  Side,
} from "@floating-ui/dom";

export type FloatOptions = { placement?: Placement };
export default function float(
  reference: ReferenceElement,
  floater: HTMLElement,
  arrowEl: HTMLElement,
  options: FloatOptions,
) {
  function updatePosition() {
    computePosition(reference, floater, {
      placement: options.placement || "top",
      middleware: [offset(8), flip(), shift({ padding: 5 }), arrow({ element: arrowEl, padding: 2 })],
    }).then(({ x, y, placement, middlewareData }) => {
      Object.assign(floater.style, {
        left: `${x}px`,
        top: `${y}px`,
      });

      const { x: arrowX, y: arrowY } = middlewareData.arrow!;
      const arrowPositions = {
        left: arrowX ? `${arrowX}px` : "",
        top: arrowY ? `${arrowY}px` : "",
        right: "",
        bottom: "",
      };

      const arrowStyles = {
        top: { bottom: "-0.5rem", transform: "" },
        right: { left: "-0.5rem", transform: "rotate(90deg)" },
        bottom: { top: "-0.5rem", transform: "rotate(180deg)" },
        left: { right: "-0.5rem", transform: "rotate(270deg)" },
      };
      const arrowStyle = arrowStyles[placement.split("-")[0] as Side];
      Object.assign(arrowEl.style, { ...arrowPositions, ...arrowStyle });
    });
  }

  updatePosition();
  // returns a cleanup function https://floating-ui.com/docs/autoUpdate
  return autoUpdate(reference, floater, updatePosition);
}
