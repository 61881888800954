import { Target } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";

export default class CombinedInputController extends ApplicationController {
  @Target operatorTarget!: HTMLSelectElement;
  @Target operandTarget!: HTMLInputElement;
  @Target combinedInputTarget!: HTMLInputElement;

  connect() {
    this.setInputValue();
  }

  setInputValue() {
    this.combinedInputTarget.value = `${this.operatorTarget.value}:${this.operandTarget.value}`;
  }
}
