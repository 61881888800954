// copied from https://github.com/ueberdosis/tiptap/pull/4587/files#diff-fb58996b9c74ae167aff784681e29c71d04150630637daae777d9795ff38779bL1
import { MarkType, ResolvedPos } from "@tiptap/pm/model";

import { PasteRule, PasteRuleFinder } from "@tiptap/core";
import { ExtendedRegExpMatchArray, MarkRange, MaybeReturnType, Range } from "@tiptap/core";
import { Mark as ProseMirrorMark, Node as ProseMirrorNode } from "@tiptap/pm/model";

export function isRegExp(value: any): value is RegExp {
  return Object.prototype.toString.call(value) === "[object RegExp]";
}

export function objectIncludes(
  object1: Record<string, any>,
  object2: Record<string, any>,
  options: { strict: boolean } = { strict: true },
): boolean {
  const keys = Object.keys(object2);

  if (!keys.length) {
    return true;
  }

  return keys.every((key) => {
    if (options.strict) {
      return object2[key] === object1[key];
    }

    if (isRegExp(object2[key])) {
      return object2[key].test(object1[key]);
    }

    return object2[key] === object1[key];
  });
}

function findMarkInSet(
  marks: ProseMirrorMark[],
  type: MarkType,
  attributes: Record<string, any> = {},
): ProseMirrorMark | undefined {
  return marks.find((item) => {
    return item.type === type && objectIncludes(item.attrs, attributes);
  });
}

function isMarkInSet(marks: ProseMirrorMark[], type: MarkType, attributes: Record<string, any> = {}): boolean {
  return !!findMarkInSet(marks, type, attributes);
}

export function getMarkRange($pos: ResolvedPos, type: MarkType, attributes: Record<string, any> = {}): Range | void {
  if (!$pos || !type) {
    return;
  }

  let start = $pos.parent.childAfter($pos.parentOffset);

  if ($pos.parentOffset === start.offset && start.offset !== 0) {
    start = $pos.parent.childBefore($pos.parentOffset);
  }

  if (!start.node) {
    return;
  }

  const mark = findMarkInSet([...start.node.marks], type, attributes);

  if (!mark) {
    return;
  }

  let startIndex = start.index;
  let startPos = $pos.start() + start.offset;
  let endIndex = startIndex + 1;
  let endPos = startPos + start.node.nodeSize;

  findMarkInSet([...start.node.marks], type, attributes);

  while (startIndex > 0 && mark.isInSet($pos.parent.child(startIndex - 1).marks)) {
    startIndex -= 1;
    startPos -= $pos.parent.child(startIndex).nodeSize;
  }

  while (endIndex < $pos.parent.childCount && isMarkInSet([...$pos.parent.child(endIndex).marks], type, attributes)) {
    endPos += $pos.parent.child(endIndex).nodeSize;
    endIndex += 1;
  }

  return {
    from: startPos,
    to: endPos,
  };
}

export function getMarksBetween(from: number, to: number, doc: ProseMirrorNode): MarkRange[] {
  const marks: MarkRange[] = [];

  // get all inclusive marks on empty selection
  if (from === to) {
    doc
      .resolve(from)
      .marks()
      .forEach((mark) => {
        const $pos = doc.resolve(from - 1);
        const range = getMarkRange($pos, mark.type);

        if (!range) {
          return;
        }

        marks.push({
          mark,
          ...range,
        });
      });
  } else {
    doc.nodesBetween(from, to, (node, pos) => {
      if (!node || node?.nodeSize === undefined) {
        return;
      }

      marks.push(
        ...node.marks.map((mark) => ({
          from: pos,
          to: pos + node.nodeSize,
          mark,
        })),
      );
    });
  }

  return marks;
}

export function isFunction(value: any): value is Function {
  return typeof value === "function";
}

function callOrReturn<T>(value: T, context: any = undefined, ...props: any[]): MaybeReturnType<T> {
  if (isFunction(value)) {
    if (context) {
      return value.bind(context)(...props);
    }

    return value(...props);
  }

  return value as MaybeReturnType<T>;
}

/**
 * Build an paste rule that adds a mark when the
 * matched text is pasted into it.
 */
export function markPasteRule(config: {
  find: PasteRuleFinder;
  type: MarkType;
  getAttributes?:
    | Record<string, any>
    | ((match: ExtendedRegExpMatchArray, event: ClipboardEvent) => Record<string, any>)
    | false
    | null;
}) {
  return new PasteRule({
    find: config.find,
    handler: ({ state, range, match, pasteEvent }) => {
      const attributes = callOrReturn(config.getAttributes, undefined, match, pasteEvent);

      if (attributes === false || attributes === null) {
        return null;
      }

      const { tr } = state;
      const captureGroup = match[match.length - 1];
      const fullMatch = match[0];
      let markEnd = range.to;

      if (captureGroup) {
        const startSpaces = fullMatch.search(/\S/);
        const textStart = range.from + fullMatch.indexOf(captureGroup);
        const textEnd = textStart + captureGroup.length;
        const marksBetweenMatch = getMarksBetween(range.from, range.to, state.doc);

        if (marksBetweenMatch.some((item) => item.mark.type === config.type)) {
          return null;
        }

        const excludedMarks = marksBetweenMatch
          .filter((item) => {
            // @ts-ignore
            const excluded = item.mark.type.excluded as MarkType[];

            return excluded.find((type) => type === config.type && type !== item.mark.type);
          })
          .filter((item) => item.to > textStart);

        if (excludedMarks.length) {
          return null;
        }

        if (textEnd < range.to) {
          tr.delete(textEnd, range.to);
        }

        if (textStart > range.from) {
          tr.delete(range.from + startSpaces, textStart);
        }

        markEnd = range.from + startSpaces + captureGroup.length;

        tr.addMark(range.from + startSpaces, markEnd, config.type.create(attributes || {}));

        tr.removeStoredMark(config.type);
      }
    },
  });
}
