import { Target, Value } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";

export default class TagToggleController extends ApplicationController {
  @Target checkboxTarget!: HTMLInputElement;
  @Target checkedInputTarget!: HTMLInputElement;
  @Target uncheckedInputTarget!: HTMLInputElement;
  @Target labelTarget!: HTMLLabelElement;

  @Value(String) stateValue!: string;
  @Value(String) nameValue!: string;

  nameValueChanged() {
    this.labelTarget.innerHTML = this.nameValue;
    this.checkedInputTarget.value = this.nameValue;
    this.uncheckedInputTarget.value = this.nameValue;
  }

  stateValueChanged() {
    if (this.stateValue === "checked") {
      this.checkboxTarget.checked = true;
      this.checkboxTarget.indeterminate = false;
    } else if (this.stateValue === "unchecked") {
      this.checkboxTarget.indeterminate = false;
      this.checkboxTarget.checked = false;
    } else {
      this.checkboxTarget.indeterminate = true;
      this.checkboxTarget.checked = false;
    }
    this.checkedInputTarget.disabled = this.checkboxTarget.indeterminate || !this.checkboxTarget.checked;
    this.uncheckedInputTarget.disabled = this.checkboxTarget.indeterminate || this.checkboxTarget.checked;
  }
}
