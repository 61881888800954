import { Targets } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";

export default class SortableItemController extends ApplicationController {
  @Targets valueTargets!: Array<Proppable<HTMLElement, { key: string; value: any }>>;

  get values() {
    return this.valueTargets.reduce(
      (valuesObj, ele) => {
        const key = this.getProp(ele, "key");
        // null can be numerically compared, ie. 2 - null === 2
        const value = this.getProp(ele, "value") || null;
        return { ...valuesObj, [key]: value };
      },
      {} as Record<string, any>,
    );
  }
}
