import { Extension } from "@tiptap/core";
import { Plugin, PluginKey, Transaction } from "@tiptap/pm/state";
import { Decoration, DecorationAttrs, DecorationSet } from "@tiptap/pm/view";
import { ReplaceAroundStep, ReplaceStep } from "@tiptap/pm/transform";
import { Node } from "@tiptap/pm/model";
import { matchAll } from "../helpers/util";

const pluginKey = new PluginKey("regexHighlighting");

export type HighlightSpec = Array<{
  regex: RegExp;
  attrs: Record<string, any>;
}>;
type DecorationAttrsMapper = (decoration: Decoration) => DecorationAttrs;

class HighlightState {
  highlightSpec: HighlightSpec;
  decorationSet: DecorationSet;

  constructor(highlightSpec: HighlightSpec, decorationSet: DecorationSet) {
    this.highlightSpec = highlightSpec;
    this.decorationSet = decorationSet;
  }

  apply(tr: Transaction) {
    const { mapping, doc } = tr;
    const newState = new HighlightState(this.highlightSpec, this.decorationSet.map(mapping, doc));
    const { mapAttrs, setHighlightSpec } = tr.getMeta(pluginKey) || {};
    if (setHighlightSpec) {
      newState.highlightSpec = setHighlightSpec;
    }
    if (newState.highlightSpec.length) {
      const dirtyRange = newState.findDirtyRange(tr);
      if (setHighlightSpec) {
        newState.highlight(doc, 0, doc.content.size);
      } else if (dirtyRange) {
        newState.highlight(doc, ...dirtyRange);
      }
      if (mapAttrs) {
        newState.mapAttrs(doc, mapAttrs);
      }
    } else {
      newState.decorationSet = DecorationSet.create(doc, []);
    }
    return newState;
  }

  highlight(doc: Node, from: number, to: number) {
    const removeDecorations: Decoration[] = [];
    const addDecorations: Decoration[] = [];
    const { text, hardBreak } = doc.type.schema.nodes;
    doc.nodesBetween(from, to, (node, pos) => {
      if (!node.isTextblock) return true;
      let nodeText = "";
      node.descendants((child) => {
        if (child.type === text) {
          nodeText += child.text;
        }
        if (child.type === hardBreak) {
          nodeText += " ";
        }
        return true;
      });
      removeDecorations.push(...this.decorationSet.find(pos, pos + node.nodeSize));
      this.highlightSpec.forEach(({ regex, attrs }) => {
        matchAll(regex, nodeText).forEach((match) => {
          const matchLength = match[0].length;
          const highlightLength = match[1].length;
          const endIndex = pos + match.index + matchLength + 1;
          const decorationAttrs = {
            nodeName: "span", // required for overlapping highlights https://discuss.prosemirror.net/t/how-to-style-overlapping-inline-decorations/3162/5
            ...attrs,
          };
          addDecorations.push(
            Decoration.inline(endIndex - highlightLength, endIndex, decorationAttrs, decorationAttrs),
          );
        });
      });
      return false;
    });
    // WONTFIX: bug with list item joining with lifted second level list item, ruining the decoration mapping allowing decorations to infinitely overlap
    this.decorationSet = this.decorationSet.remove(removeDecorations).add(doc, addDecorations);
  }

  findDirtyRange(tr: Transaction): [number, number] | null {
    const { doc, steps } = tr;
    const stepMaps = steps.map((step) => step.getMap());
    // multi constructor guardInstance
    const dirtySteps = steps.filter(
      (step): step is ReplaceAroundStep | ReplaceStep =>
        step instanceof ReplaceStep || step instanceof ReplaceAroundStep,
    );
    if (!dirtySteps.length) return null;
    const { minFrom, maxTo } = dirtySteps.reduce(
      (accumulatedRange, step, i) => {
        const stepRange = stepMaps.slice(i).reduce<{
          from: number;
          to: number;
        }>(({ from, to }, map) => ({ from: map.map(from, -1), to: map.map(to, 1) }), { from: step.from, to: step.to });
        return {
          minFrom: Math.min(accumulatedRange.minFrom, stepRange.from),
          maxTo: Math.max(accumulatedRange.maxTo, stepRange.to),
        };
      },
      { minFrom: doc.content.size, maxTo: 0 },
    );
    return [minFrom, maxTo];
  }

  mapAttrs(doc: Node, mapFn: DecorationAttrsMapper) {
    const newDecorations = this.decorationSet.find().map((decoration) => {
      const attrs = mapFn(decoration);
      return Decoration.inline(decoration.from, decoration.to, attrs, attrs);
    });
    const newDecorationSet = DecorationSet.create(doc, newDecorations);
    this.decorationSet = newDecorationSet;
  }
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    regexHighlighting: {
      mapHighlightAttrs: (fn: DecorationAttrsMapper) => ReturnType;
      setHighlightSpec: (spec: HighlightSpec) => ReturnType;
    };
  }
}
export default Extension.create({
  name: "regexHighlighting",
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: pluginKey,
        state: {
          init(_, state) {
            return new HighlightState([], DecorationSet.create(state.doc, []));
          },
          apply(tr, prevState) {
            return prevState.apply(tr);
          },
        },
        props: {
          decorations(state) {
            return this.getState(state)?.decorationSet;
          },
        },
      }),
    ];
  },
  addCommands: () => ({
    mapHighlightAttrs:
      (fn: DecorationAttrsMapper) =>
      ({ chain }) =>
        chain()
          .command(({ state }) => {
            const { tr } = state;
            tr.setMeta(pluginKey, { mapAttrs: fn });
            return true;
          })
          .preventHistory()
          .run(),
    setHighlightSpec:
      (spec: HighlightSpec) =>
      ({ chain }) =>
        chain()
          .command(({ state }) => {
            const { tr } = state;
            tr.setMeta(pluginKey, { setHighlightSpec: spec });
            return true;
          })
          .preventHistory()
          .run(),
  }),
});
