import { Application } from "@hotwired/stimulus";
import ApplicationController from "../controllers/application_controller";

export default class Stimulus {
  private static identifierLookup = new Map<Constructor<ApplicationController>, string>();
  private static application?: Application;

  static start() {
    this.application = Application.start();
  }

  static register<T extends Constructor<ApplicationController>>(identifier: string, controller: T) {
    this.application!.register(identifier, controller);
    this.identifierLookup.set(controller, identifier);
  }

  static getController<T extends Constructor<ApplicationController>>(
    ele: HTMLElement,
    controller: T,
  ): InstanceType<T> | null {
    return this.application!.getControllerForElementAndIdentifier(
      ele,
      this.identifierLookup.get(controller)!,
    ) as InstanceType<T>;
  }
}
