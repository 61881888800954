import Highcharts from "highcharts";
import { Value } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import columnChartOptions from "../helpers/highcharts_options/column";
import lineseriesChartOptions from "../helpers/highcharts_options/lineseries";
import sparkareaChartOptions from "../helpers/highcharts_options/sparkarea";

enum ChartType {
  Lineseries = "lineseries",
  Column = "column",
  Sparkarea = "sparkarea",
}

export default class HighchartsController extends ApplicationController {
  @Value(String) typeValue!: ChartType;
  @Value(Object) configValue!: object;

  chart!: Highcharts.Chart;

  chartOptions(type: ChartType, config: any) {
    switch (type) {
      case ChartType.Lineseries:
        return lineseriesChartOptions(config);
      case ChartType.Column:
        return columnChartOptions(config);
      case ChartType.Sparkarea:
        return sparkareaChartOptions(config);
      default:
        throw new Error(`Unknown chart type: ${type}`);
    }
  }

  connect() {
    this.chart = Highcharts.chart(this.element, this.chartOptions(this.typeValue, this.configValue));
    this.dispatch("loaded");
  }
}
