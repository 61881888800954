import ScrollSpy from "bootstrap/js/dist/scrollspy";
import { Target, Value } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import { stringToHtmlId } from "../helpers/util";

function headingLevel(ele: HTMLHeadingElement) {
  return parseInt(ele.tagName.replace(/[\D]/g, ""));
}

function uniqueHeadingIds(headingIds: Array<string>) {
  const uniqueIds = new Set();
  return headingIds.map((id) => {
    let uniqueId = id;
    let i = 1;
    while (uniqueIds.has(uniqueId)) {
      uniqueId = `${id}-${i}`;
      i += 1;
    }
    uniqueIds.add(uniqueId);
    return uniqueId;
  });
}

export default class ArticleController extends ApplicationController {
  @Target tocTarget!: HTMLElement;
  @Target bodyTarget!: HTMLElement;

  @Value(Number) tocLevelsValue!: number;

  connect() {
    this.buildToc();
  }

  buildToc() {
    const headingElements = [...this.bodyTarget.querySelectorAll<HTMLHeadingElement>("h1, h2, h3, h4, h5, h6")];
    if (!headingElements.length) return;
    const topLevelHeading = Math.min(...headingElements.map((ele) => headingLevel(ele)));
    const headingIds = uniqueHeadingIds(headingElements.map((ele) => stringToHtmlId(ele.textContent!)));
    this.tocTarget.innerHTML = "";
    headingElements.forEach((ele, i) => {
      ele.id = headingIds[i];
      const indentLevel = headingLevel(ele) - topLevelHeading;
      if (indentLevel > this.tocLevelsValue - 1) {
        return;
      }
      const linkHtml = `<li class='nav-item' style='padding-left:${indentLevel}em'>
                          <a class='nav-link' href='#${ele.id}'>${ele.innerText}</a>
                        </li>`;
      this.tocTarget.insertAdjacentHTML("beforeend", linkHtml);
    });

    new ScrollSpy(document.body, {
      target: this.tocTarget.parentElement!,
    });
  }
}
