import { Extension } from "@tiptap/core";
import { debounce, DebouncedFunc } from "lodash-es";
import { Target, Targets, Value } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import RegexHighlighting, { HighlightSpec } from "../wysiwyg_extensions/regex_highlighting";
import Stimulus from "../helpers/stimulus";
import WysiwygController from "./wysiwyg_controller";
import SortableController from "./sortable_controller";
import DisplayController from "./display_controller";
import { requestSubmit } from "../helpers/dom";

// colors with alpha (e.g. hsla, rgba) don't paste into Google Docs
// using alpha would allow us to fix kerning issues (background-color overlapping kerned quotes e.g. 'J) and display overlapping terms better
const highlightStyle = "background-color:hsl(45, 100%, 90%)"; // same as $mark-bg
const highlightActiveStyle = "background-color:hsl(225, 100%, 95%)"; // adjusted from $blue

export default class EditorController extends ApplicationController {
  @Value(Boolean) isSavingValue!: boolean;

  @Target formTarget!: HTMLFormElement;
  readonly hasFormTarget!: boolean;
  @Target saveButtonTarget!: HTMLElement;
  readonly hasSaveButtonTarget!: boolean;
  @Target termsListTarget!: HTMLElement;
  @Target highlightSwitchTarget!: HTMLInputElement;
  @Target semanticGroupingSwitchTarget!: HTMLInputElement;
  @Target wysiwygTarget!: HTMLElement;
  @Targets termTargets!: Array<Proppable<HTMLElement, { regex: RegexObject; termId: number }>>;

  debouncedSave!: DebouncedFunc<() => void>;

  extendWysiwyg(event: StimulusEvent) {
    this.debouncedSave = debounce(this.save, 750);
    event.detail.extensions.push(
      Extension.create({
        name: "editorSave",
        onUpdate: () => {
          this.isSavingValue = true;
          this.debouncedSave();
        },
      }),
      RegexHighlighting,
    );
  }

  save() {
    this.isSavingValue = true;
    if (this.hasFormTarget) {
      requestSubmit(this.formTarget);
    }
  }

  handleSaved() {
    this.isSavingValue = false;
    this.sortTerms();
  }

  sortTerms() {
    if (this.semanticGroupingSwitchTarget.checked) {
      this.termsSortableController.sortBy([
        ["semantic_group", "desc"],
        ...this.termsSortableController.tiebreakersValue,
      ]);
      this.termsSortableController.selectTarget.disabled = true;
    } else {
      this.termsSortableController.sort();
      this.termsSortableController.selectTarget.disabled = false;
    }
  }

  highlightActiveTerm(event: StimulusEvent<{ termId: number }>) {
    const { termId } = event.params;
    this.wysiwygEditor.commands.mapHighlightAttrs((decoration) => {
      const { spec } = decoration;
      if (spec["data-term-id"] === termId) {
        return { ...spec, style: highlightActiveStyle };
      }
      return spec;
    });
  }

  clearActiveTerm() {
    this.wysiwygEditor.commands.mapHighlightAttrs((decoration) => ({ ...decoration.spec, style: highlightStyle }));
  }

  applyHighlighting() {
    if (this.highlightSwitchTarget.checked) {
      this.wysiwygEditor.commands.setHighlightSpec(this.termsHighlightSpec);
    } else {
      this.wysiwygEditor.commands.setHighlightSpec([]);
    }
  }

  isSavingValueChanged() {
    if (!this.hasSaveButtonTarget) return;
    Promise.resolve().then(() => {
      const saveButtonDisplayController = Stimulus.getController(this.saveButtonTarget, DisplayController)!;
      if (this.isSavingValue) {
        saveButtonDisplayController.showOnly("saving");
      } else {
        saveButtonDisplayController.showOnly("saved");
      }
    });
  }

  confirmLeave(event: StimulusEvent) {
    // Check for unsaved data
    if (this.isSavingValue) {
      event.preventDefault();
      // Chrome requires returnValue to be set (https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload)
      event.returnValue = false;
    }
  }

  private get termsSortableController() {
    return Stimulus.getController(this.termsListTarget, SortableController)!;
  }

  private get wysiwygEditor() {
    return Stimulus.getController(this.wysiwygTarget, WysiwygController)!.editor;
  }

  private get termsHighlightSpec(): HighlightSpec {
    return this.termTargets.map((termTarget) => {
      const regexObj = this.getProp(termTarget, "regex");
      const regex = new RegExp(regexObj.source, regexObj.options);
      const attrs = {
        "data-action": "click->dropdown#toggle",
        "data-dropdown-menu-id-param": termTarget.dataset.dropdownMenuIdParam,
        "data-dropdown-placement-param": "top",
        "data-dropdown-disable-toggle-aria-param": "true",
        "data-term-id": this.getProp(termTarget, "termId"),
        style: highlightStyle,
      };
      return { regex, attrs };
    });
  }
}
