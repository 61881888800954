import { Target } from "@vytant/stimulus-decorators";
import { appendStimulusAction } from "../helpers/dom";
import float from "../helpers/float";
import ApplicationController from "./application_controller";

type Keybind = string | undefined;

export default class TooltipController extends ApplicationController {
  @Target containerTarget!: HTMLElement;
  @Target innerTarget!: HTMLElement;
  @Target arrowTarget!: HTMLElement;
  @Target keybindTarget!: HTMLElement;

  hideTimeoutId?: ReturnType<typeof setTimeout>;
  showTimeoutId?: ReturnType<typeof setTimeout>;
  unfloatFn?: () => void;

  activate(event: StimulusEvent<{ body: string; keybind: Keybind }>) {
    this.queueShow(event);
    appendStimulusAction(event.currentTarget, "mouseleave", "tooltip#queueHide");
  }

  queueShow(event: StimulusEvent<{ body: string; keybind: Keybind }>) {
    const trigger = event.currentTarget;
    const { body, keybind } = event.params;
    if (!body && !keybind) throw new Error("missing body");
    this.showTimeoutId = setTimeout(() => {
      this.hide();
      if (body) this.innerTarget.innerHTML = body;
      if (keybind) this.keybindTarget.innerHTML = this.adaptedKeybind(keybind);
      this.unfloatFn = float(trigger, this.containerTarget, this.arrowTarget, { placement: "top" });
      this.containerTarget.classList.add("show");
    }, 300);
  }

  queueHide() {
    clearTimeout(this.showTimeoutId);
    this.hideTimeoutId = setTimeout(() => {
      this.hide();
    }, 100);
  }

  cancelHide() {
    clearTimeout(this.hideTimeoutId);
  }

  hide() {
    if (this.unfloatFn) this.unfloatFn();
    this.containerTarget.classList.remove("show");
  }

  adaptedKeybind(keybind: string) {
    if (this.isMac) {
      return keybind.replace(/Mod/g, "⌘").replace(/Alt/g, "Option");
    }

    return keybind.replace(/Mod/g, "Ctrl");
  }

  get isMac() {
    return navigator.platform.toUpperCase().match("MAC");
  }
}
