import Cookies from "js-cookie";
import { Editor, Extension, UnionCommands } from "@tiptap/core";
import Blockquote from "@tiptap/extension-blockquote";
import BulletList from "@tiptap/extension-bullet-list";
import CodeBlock from "@tiptap/extension-code-block";
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Code from "@tiptap/extension-code";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";

import History from "@tiptap/extension-history";
import {
  emDash,
  openDoubleQuote,
  closeDoubleQuote,
  openSingleQuote,
  closeSingleQuote,
} from "@tiptap/extension-typography";

import { Value, Target, Targets } from "@vytant/stimulus-decorators";

import BlurredSelectionExtension from "../wysiwyg_extensions/blurred_selection";
import ExtraCommandsExtension from "../wysiwyg_extensions/extra_commands";

import ApplicationController from "./application_controller";
import { jsonCast } from "../helpers/util";
import Stimulus from "../helpers/stimulus";
import DropdownMenuController from "./dropdown_menu_controller";

// Patch for https://linear.app/clearscope/issue/PRO-301/editor-copy-and-paste-url-bug#comment-2956a0e9
import { markPasteRule } from "../wysiwyg_extensions/paste_rule_patch";

import { find } from "linkifyjs";

const fullWidthClass = "wysiwyg__editor--full-width";
const fullWidthCookieKey = "wysiwygFullWidth";

export default class WysiwygController extends ApplicationController {
  @Value(Boolean) isReadonlyValue!: boolean;
  @Target editorTarget!: HTMLElement;
  @Target formatBtnLabelTarget!: HTMLElement;
  @Target formatListTarget!: HTMLElement;
  @Target formInputTarget!: HTMLInputElement;
  @Target fullWidthSwitchTarget!: HTMLInputElement;
  @Target linkFormBtnTarget!: HTMLButtonElement;
  @Target linkFormInputTarget!: HTMLInputElement;
  @Target linkFormMenuTarget!: HTMLElement;
  @Target linkMenuAnchorTarget!: HTMLAnchorElement;
  @Target linkMenuTarget!: HTMLElement;
  @Targets commandButtonTargets!: Array<Proppable<HTMLButtonElement, { isActiveArgs: [string, {} | undefined] }>>;

  readonly hasFullWidthSwitchTarget!: boolean;
  readonly hasFormInputTarget!: boolean;
  readonly hasLinkMenuTarget!: boolean;
  editor!: Editor;

  connect() {
    // https://github.com/hotwired/stimulus/issues/201
    // https://discuss.hotwired.dev/t/cant-load-child-controller-by-target/381/4
    Promise.resolve().then(() => {
      const content = this.editorTarget.innerHTML;
      this.editorTarget.innerHTML = "";
      if (this.hasFullWidthSwitchTarget) {
        this.fullWidthSwitchTarget.checked = jsonCast(Cookies.get(fullWidthCookieKey), false);
        this.applyFullWidth();
      }
      const config = { ...this.defaultConfig(), content };
      // dispatch event for modification
      const event = this.dispatch("initialize", { detail: config });
      this.editor = new Editor({
        ...event.detail,
        editorProps: {
          handleDOMEvents: {
            // Prosemirror's default copy/cut handlers don't allow for copying decorations
            // returning true prevents going up the stack of eventHandlers, disabling the default eventHandlers
            copy: () => true,
            cut: () => true,
          },
        },
      });
      this.dispatch("initialized", { detail: config });
    });
  }

  insertHtml(event: StimulusEvent<{ html: string }>) {
    event.preventDefault();
    const { html } = event.params;
    this.editor.chain().focus().insertContent(html).run();
  }

  command(event: StimulusEvent<{ command: keyof UnionCommands; commandArgs: string[] }>) {
    const { command, commandArgs = [] } = event.params;
    // call to any command with different args is impossible to type
    this.editor
      .chain()
      .focus()
      // @ts-ignore
      [command](...commandArgs)
      .run();
  }

  showLinkForm() {
    this.editor.chain().focus().extendMarkRange(Link.name).blur().run();
    const { state } = this.editor;
    const linkMarkType = this.editor.schema.marks[Link.name];
    const { from, to } = state.selection;
    this.linkFormInputTarget.value = "";
    state.doc.nodesBetween(from, to, (node) => {
      if (this.linkFormInputTarget.value.length) return false;
      const linkMark = node.marks.find((mark) => mark.type === linkMarkType);
      if (linkMark) {
        this.linkFormInputTarget.value = linkMark.attrs.href;
        return false;
      }
      return true;
    });
    this.toggleLinkFormMenu();
    // https://stackoverflow.com/questions/60822044/settimeout-not-working-properly-how-to-get-focus-in-input-field
    setTimeout(() => {
      this.linkFormInputTarget.focus();
    }, 10);
  }

  removeLink(event: StimulusEvent) {
    event.preventDefault();
    this.hideLinkMenu();
    this.editor.chain().focus().unsetLink().run();
  }

  insertLink(event: StimulusEvent) {
    event.preventDefault();
    const inputVal = this.linkFormInputTarget.value;
    if (!inputVal) {
      this.removeLink(event);
      return;
    }
    let href;
    try {
      ({ href } = new URL(inputVal));
    } catch {
      try {
        ({ href } = new URL(`http://${inputVal}`));
      } catch {
        this.editor.commands.focus();
        return;
      }
    }
    if (this.editor.state.selection.empty) {
      this.editor
        .chain()
        .focus()
        .insertContent({
          type: "text",
          text: href,
          marks: [
            {
              type: Link.name,
              attrs: {
                href,
              },
            },
          ],
        })
        .run();
    } else {
      this.editor.chain().focus().setLink({ href }).run();
    }
    this.hideLinkFormMenu();
  }

  showLinkMenu(anchorNode: HTMLAnchorElement) {
    if (this.hasLinkMenuTarget) {
      Stimulus.getController(this.linkMenuTarget, DropdownMenuController)!.show(anchorNode, { placement: "bottom" });
    }
  }

  hideLinkMenu() {
    if (this.hasLinkMenuTarget) {
      Stimulus.getController(this.linkMenuTarget, DropdownMenuController)!.hide();
    }
  }

  toggleLinkFormMenu() {
    Stimulus.getController(this.linkFormMenuTarget, DropdownMenuController)!.toggle(this.linkFormBtnTarget, {
      placement: "bottom",
    });
  }

  hideLinkFormMenu() {
    Stimulus.getController(this.linkFormMenuTarget, DropdownMenuController)!.hide();
  }

  defaultConfig() {
    return {
      element: this.editorTarget,
      editable: !this.isReadonlyValue,
      parseOptions: {
        // `editor.getHTML()` shouldn't collapse whitespace
        preserveWhitespace: true,
      },
      extensions: [
        // core
        Document,
        Text,
        // Nodes
        Paragraph,
        Heading.extend({
          addInputRules() {
            return [];
          },
        }),
        Blockquote.extend({
          content: "inline*",
          addInputRules() {
            return [];
          },
          addKeyboardShortcuts() {
            return {};
          },
        }),
        CodeBlock.extend({
          addInputRules() {
            return [];
          },
          addKeyboardShortcuts() {
            return {};
          },
        }),
        BulletList,
        OrderedList,
        ListItem.extend({
          addKeyboardShortcuts() {
            return {
              Enter: () => this.editor.commands.splitListItem(this.name),
              Tab: () => this.editor.commands.sinkListItem(this.name),
              "Shift-Tab": () => this.editor.commands.liftListItem(this.name),
              Backspace: () => {
                const { state } = this.editor;
                const { $anchor } = state.selection;
                const listTypes = Object.values(this.editor.schema.nodes).filter(
                  (node) => node.spec.content === `${this.name}+`,
                );
                if ($anchor.nodeBefore || $anchor.pos === 0) {
                  return false;
                }
                const $before = state.doc.resolve($anchor.pos - 1);
                if ($before.parent?.type === this.type && $before.nodeBefore) {
                  return this.editor.commands.liftToTop();
                }
                if ($before.nodeBefore?.type && listTypes.includes($before.nodeBefore.type)) {
                  return this.editor.commands.joinPrevTextblock();
                }
                return false;
              },
              Delete: () => {
                const { state } = this.editor;
                const { $anchor } = state.selection;
                const endOfLine = !$anchor.nodeAfter;
                if (!endOfLine) {
                  return false;
                }
                const $afterAnchor = state.doc.resolve($anchor.pos + 1);
                if ($afterAnchor.parent?.type === this.type) {
                  return this.editor.commands.absorbNextTextblock();
                }
                return false;
              },
            };
          },
        }),
        HardBreak,
        // Marks
        Bold,
        Italic,
        Underline,
        Code,
        Link.extend({
          addKeyboardShortcuts: () => ({
            "Mod-k": () => {
              this.showLinkForm();
              return true;
            },
          }),
          onSelectionUpdate: () => {
            this.hideLinkMenu();
            if (this.editor.isActive(Link.name)) {
              const { $anchor } = this.editor.state.selection;
              const anchorNode = this.editor.view.domAtPos($anchor.pos).node.parentElement?.closest("a");
              if (anchorNode) {
                this.linkMenuAnchorTarget.innerHTML = anchorNode.href;
                this.linkMenuAnchorTarget.href = anchorNode.href;
                this.showLinkMenu(anchorNode);
              }
            }
          },

          addPasteRules() {
            return [
              markPasteRule({
                find: (text) =>
                  find(text)
                    .filter((link) => {
                      if (this.options.validate) {
                        return this.options.validate(link.value);
                      }

                      return true;
                    })
                    .filter((link) => link.isLink)
                    .map((link) => ({
                      text: link.value,
                      index: link.start,
                      data: link,
                    })),
                type: this.type,
                getAttributes: (match, pasteEvent) => {
                  const html = pasteEvent.clipboardData?.getData("text/html");
                  const hrefRegex = /href="([^"]*)"/;
                  const existingLink = html?.match(hrefRegex);

                  if (existingLink) {
                    return {
                      href: existingLink[1],
                    };
                  }

                  return {
                    href: match.data?.href,
                  };
                },
              }),
            ];
          },
        }).configure({
          autolink: false,
          openOnClick: false,
          HTMLAttributes: {
            // don't want unnecessary attributes to be copyable to clipboard
            target: null,
            rel: null,
          },
        }),
        // Other
        History,
        BlurredSelectionExtension,
        ExtraCommandsExtension,
        this.menuExtension,
        this.typographyExtension,
        ...(this.hasFormInputTarget ? [this.formInputExtension] : []),
        Placeholder.configure({
          placeholder: "Draft or paste your content here...",
        }),
      ],
    };
  }

  get typographyExtension() {
    return Extension.create({
      name: "typography",
      addInputRules() {
        return [emDash(), openDoubleQuote(), closeDoubleQuote(), openSingleQuote(), closeSingleQuote()];
      },
    });
  }

  get menuExtension() {
    return Extension.create({
      name: "menu",
      onTransaction: () => {
        this.commandButtonTargets.forEach((ele) => {
          const isActiveArgs = this.getProp(ele, "isActiveArgs");
          const isActive = isActiveArgs && this.editor.isActive(...isActiveArgs);
          if (isActive) {
            ele.classList.add("active");
            if (this.formatListTarget.contains(ele)) {
              this.formatBtnLabelTarget.innerHTML = ele.innerHTML;
            }
          } else {
            ele.classList.remove("active");
          }
        });
      },
    });
  }

  get formInputExtension() {
    return Extension.create({
      name: "formInput",
      onUpdate: () => {
        this.formInputTarget.value = this.editor.getHTML();
      },
    });
  }

  applyFullWidth() {
    const { checked } = this.fullWidthSwitchTarget;
    Cookies.set(fullWidthCookieKey, checked.toString(), { expires: 365 });
    if (checked) {
      this.editorTarget.classList.add(fullWidthClass);
    } else {
      this.editorTarget.classList.remove(fullWidthClass);
    }
  }
}
