import { Options } from "highcharts";

export default function columnChartOptions(config: {
  series: Array<Highcharts.SeriesOptionsType>;
  categories: Array<string>;
  xLabelsStep?: number;
}) {
  return {
    chart: {
      type: "column",
    },
    xAxis: {
      type: "category", // required for when categories are passed in through `seriesValue`
      // categories needs to be undefined when passing categories through `seriesValue`
      categories: config.categories,
      crosshair: false,
      labels: {
        step: config.xLabelsStep,
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: config.series,
  } as Options;
}
