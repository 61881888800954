import * as Sentry from "@sentry/browser";
import type { ErrorEvent, EventHint } from "@sentry/types";
import ContextError from "./error";

const defaultIgnoreErrors = [
  "ResizeObserver loop limit exceeded", // 'ResizeObserver loop limit exceeded': https://stackoverflow.com/a/50387233/823873
  "Non-Error promise rejection captured with value: Timeout", // Recaptcha Timeout: https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
];

function attachSentryContext(event: ErrorEvent, hint: EventHint) {
  const error = hint.originalException;

  if (error instanceof ContextError) {
    event.extra = { ...event.extra, ...error.extra };
  }

  return event;
}

export default function initSentry() {
  Sentry.init({
    dsn: "https://5005e0ac951249b597449a7a38f76551@o9072.ingest.sentry.io/177370",
    environment: NODE_ENV,
    ignoreErrors: defaultIgnoreErrors,
    beforeSend: attachSentryContext,
    integrations: [
      // https://docs.sentry.io/platforms/javascript/guides/node/configuration/filtering/#using-thirdpartyerrorfilterintegration
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ["clearscope-js"], // must match the applicationKey specified in esbuild.config.cjs
        behaviour: "drop-error-if-exclusively-contains-third-party-frames",
      }),
    ],
  });
}
