import setTzCookie from "./helpers/set_tz_cookie";
import initSentry from "./helpers/sentry";
import setupHighcharts from "./helpers/setup_highcharts";
import Stimulus from "./helpers/stimulus";
import controllers from "./controllers/index";

initSentry();
setTzCookie();
setupHighcharts();

Stimulus.start();
controllers.forEach((controller: { name: string; module: { default: any } }) => {
  Stimulus.register(controller.name, controller.module.default);
});
