export function ciEquals(s1: string, s2: string) {
  // ~5x faster than localeCompare, but doesn't work with Unicode characters
  return s1.toUpperCase() === s2.toUpperCase();
}

export function appendParamsToUrl(url: URL, data: URLSearchParams | FormData) {
  const { searchParams } = url;
  data.forEach((v, k) => {
    // type FormDataEntryValue = File | string; -- we want only string
    if (typeof v === "string") {
      searchParams.append(k, v);
    }
  });
}

// case-insensitive includes
export function ciIncludes(strings: string[], value: string) {
  return strings.some((string) => ciEquals(string, value));
}

export function stringToHtmlId(string: string) {
  return string
    .replace(/[\s_]+/g, "-")
    .replace(/[^a-z0-9-]/gi, "")
    .replace(/^[^a-z]+/i, "") // HTML4 ids must start with [a-zA-Z_] https://www.w3.org/TR/html4/types.html#type-id
    .toLowerCase();
}

export function jsonCast(value: string | undefined, fallback?: any) {
  if (value === undefined) return fallback;
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

export function getCssProperty(property: string, element = document.documentElement) {
  const rootStyle = getComputedStyle(element);
  // trim to avoid leading whitespace from css formatting
  return rootStyle.getPropertyValue(property).trim();
}

// https://www.skovy.dev/blog/typescript-filter-array-with-type-guard?seed=kcf7kf
// https://stackoverflow.com/questions/56326396/typescript-type-check-not-working-with-array-filter
export function guardInstance<T>(klass: Constructor<T>) {
  return (o: any): o is T => o instanceof klass;
}

// polyfill for String.prototype.matchAll (es2020)"
export function matchAll(regex: RegExp, text: string) {
  let match = regex.exec(text);
  const matches = [];
  while (match !== null) {
    matches.push(match);
    match = regex.exec(text);
  }
  return matches;
}
