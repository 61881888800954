import Toast from "bootstrap/js/dist/toast";
import { Target, Value } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";

export default class ToastController extends ApplicationController {
  @Target noticeTemplateTarget!: HTMLTemplateElement;
  @Target noticeContentTarget!: HTMLElement;
  @Target alertTemplateTarget!: HTMLTemplateElement;
  @Target alertContentTarget!: HTMLElement;
  @Target containerTarget!: HTMLElement;
  @Value(String) noticeValue!: string;
  @Value(String) alertValue!: string;

  activeToast?: Toast;

  connect() {
    if (this.noticeValue.length) {
      this.notice(this.noticeValue);
    }
    if (this.alertValue.length) {
      this.alert(this.alertValue);
    }
  }

  insertNotice(event: StimulusEvent) {
    this.notice(event.detail.html);
  }

  notice(message: string) {
    this.containerTarget.innerHTML = this.noticeTemplateTarget.innerHTML;
    this.noticeContentTarget.innerHTML = message;
    const toastElement = this.containerTarget.firstElementChild;
    if (toastElement) {
      const toast = Toast.getOrCreateInstance(toastElement, {
        autohide: true,
        delay: 4000,
        animation: false,
      });
      toast.show();
      this.activeToast = toast;
    }
  }

  insertAlert(event: StimulusEvent) {
    this.alert(event.detail.html);
  }

  alert(message: string) {
    this.containerTarget.innerHTML = this.alertTemplateTarget.innerHTML;
    this.alertContentTarget.innerHTML = message;
    const toastElement = this.containerTarget.firstElementChild;
    if (toastElement) {
      const toast = Toast.getOrCreateInstance(toastElement, {
        autohide: false,
        animation: false,
      });
      toast.show();
      this.activeToast = toast;
    }
  }

  hide() {
    this.activeToast?.hide();
  }
}
