import { Value } from "@vytant/stimulus-decorators";
import { parseISO } from "date-fns";
import { format } from "date-fns-tz";
import ApplicationController from "./application_controller";

export default class TimeConverterController extends ApplicationController {
  @Value(String) datetimeValue!: string;
  @Value(String) formatValue!: string;

  datetimeValueChanged() {
    const date = parseISO(this.datetimeValue);
    this.element.innerHTML = format(date, this.formatValue);
  }
}
