import { Options, SeriesOptionsType } from "highcharts";

function markIsolatedPoints(seriesArray: Array<SeriesOptionsType>) {
  return seriesArray.map((series: SeriesOptionsType): SeriesOptionsType => {
    // @ts-ignore
    const origSeriesData = series.data as Array<any>;

    if (origSeriesData.length < 2) return series;

    const interval = origSeriesData[1].x - origSeriesData[0].x;
    const newSeriesData = [] as Array<any>;

    origSeriesData.forEach((point, i) => {
      const prevPoint = origSeriesData[i - 1];
      const nextPoint = origSeriesData[i + 1];

      // if the point has adjacent gaps in data, we make it visible by
      // inserting an adjacent point a third of an interval away
      if (point.y && prevPoint?.y == null && nextPoint?.y == null) {
        const newPoint = { ...point };

        newPoint.x += interval / 3;
        newSeriesData.push(newPoint);
      }
      newSeriesData.push(point);
    });
    return { ...series, data: newSeriesData } as SeriesOptionsType;
  }) as Array<SeriesOptionsType>;
}

export default function sparkareaChartOptions(config: {
  series: Array<Highcharts.SeriesOptionsType>;
  yAxisReversed?: boolean;
}) {
  return {
    chart: {
      type: "area",
      margin: [0, 0, 0, 0],
    },
    xAxis: {
      crosshair: false,
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      reversed: config.yAxisReversed,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    series: markIsolatedPoints(config.series),
    plotOptions: {
      area: {
        fillOpacity: 0.25,
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
        // https://api.highcharts.com/highcharts/plotOptions.area.threshold
        threshold: config.yAxisReversed ? Infinity : null,
      },
    },
  } as Options;
}
