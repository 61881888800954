import { Target } from "@vytant/stimulus-decorators";
import FocusTrap from "bootstrap/js/dist/util/focustrap";
import float, { FloatOptions } from "../helpers/float";
import ApplicationController from "./application_controller";
import { autofocus } from "../helpers/dom";

const showClass = "show";

export default class DropdownMenuController extends ApplicationController {
  @Target arrowTarget!: HTMLElement;
  @Target containerTarget!: HTMLElement;
  @Target originalContentTemplateTarget!: HTMLElement;
  @Target spinnerTarget!: HTMLElement;

  focustrap = new FocusTrap({ trapElement: this.containerTarget });
  anchor?: HTMLElement;
  unfloatFn?: () => void;

  toggle(anchor: HTMLElement, options = {}) {
    if (this.isActive) {
      this.hide();
    } else {
      this.show(anchor, options);
    }
  }

  show(anchor: HTMLElement, options: FloatOptions = {}) {
    if (this.originalContent.length) {
      this.containerTarget.innerHTML = this.originalContent;
    }
    this.anchor = anchor;
    this.unfloatFn = float(this.anchor, this.element, this.arrowTarget, options);
    this.element.classList.add(showClass);
    this.manageFocus();
    this.dispatch("show");
  }

  insert(event: StimulusEvent) {
    if (!this.originalContent.length) {
      this.originalContent = this.containerTarget.innerHTML;
    }
    this.containerTarget.innerHTML = event.detail.html;
    autofocus(this.containerTarget, { preventScroll: true });
  }

  hide() {
    if (!this.isActive) return;
    this.element.classList.remove(showClass);
    this.unfloatFn?.();
    this.dispatch("hide");
  }

  manageFocus() {
    this.focustrap.activate();
    autofocus(this.containerTarget, { preventScroll: true });
    this.handleKeyboardClose = this.handleKeyboardClose.bind(this);
    document.addEventListener("keydown", this.handleKeyboardClose);
  }

  handleKeyboardClose(event: KeyboardEvent) {
    if (event.key !== "Escape") return;

    this.focustrap.deactivate();
    this.anchor?.focus();
    document.removeEventListener("keydown", this.handleKeyboardClose);
    this.hide();
  }

  get isActive() {
    return this.element.classList.contains(showClass);
  }

  private get originalContent() {
    return this.originalContentTemplateTarget.innerHTML;
  }

  private set originalContent(value: string) {
    this.originalContentTemplateTarget.innerHTML = value;
  }
}
