import { Targets } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import { show, hide, toggleDisplay } from "../helpers/dom";

export default class DisplayController extends ApplicationController {
  @Targets itemTargets!: Array<HTMLElement>;

  flip() {
    this.itemTargets.forEach((item) => {
      toggleDisplay([item]);
    });
  }

  showOnly(itemId: string) {
    hide(this.itemTargets);
    show([this.propSelector("id", itemId)]);
  }
}
