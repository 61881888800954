import { Value } from "@vytant/stimulus-decorators";
import { parseISO, formatDistanceToNow } from "date-fns";
import ApplicationController from "./application_controller";

export default class TimeAgoConverterController extends ApplicationController {
  @Value(String) datetimeValue!: string;
  @Value(String) formatValue!: string;

  connect() {
    setInterval(() => {
      this.updateTimeAgo();
    }, 60000);
  }

  datetimeValueChanged() {
    this.updateTimeAgo();
  }

  updateTimeAgo() {
    this.element.innerHTML = formatDistanceToNow(this.dateTime, { addSuffix: true });
  }

  get dateTime() {
    return parseISO(this.datetimeValue);
  }
}
