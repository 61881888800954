import { toPairs } from "lodash-es";
import { Target, Value } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import { show, hide, cloneElement } from "../helpers/dom";
import Stimulus from "../helpers/stimulus";
import TagToggleController from "./tag_toggle_controller";

export default class TagManagerController extends ApplicationController {
  @Target newInputTarget!: HTMLInputElement;
  @Target newSubmitTarget!: HTMLButtonElement;
  @Target newTagNameTarget!: HTMLElement;
  @Target toggleTemplateTarget!: HTMLTemplateElement;
  @Target selectedTogglesTarget!: HTMLElement;
  @Target selectedGroupTarget!: HTMLElement;
  @Target availableTogglesTarget!: HTMLElement;
  @Target availableGroupTarget!: HTMLElement;

  @Value(Object) tagStatesValue!: Record<string, string>;

  connect() {
    this.resetNewForm();
  }

  create(event: StimulusEvent) {
    event.preventDefault();
    const newTagName = this.newInputTarget.value;
    if (newTagName.trim().length) {
      this.setTagValue(newTagName, "checked");
      this.resetNewForm();
    }
  }

  toggle(event: StimulusEvent) {
    const tagToggleElement = event.currentTarget;
    const tagToggle = Stimulus.getController(tagToggleElement, TagToggleController)!;
    const value = tagToggle.stateValue;
    const name = tagToggle.nameValue;
    if (["checked", "indeterminate"].includes(value)) {
      this.setTagValue(name, "unchecked");
    } else {
      this.setTagValue(name, "checked");
    }
  }

  updateNewForm() {
    const inputValue = this.newInputTarget.value;
    if (inputValue.length) {
      show([this.newSubmitTarget]);
      this.newTagNameTarget.textContent = inputValue;
    } else {
      hide([this.newSubmitTarget]);
    }
  }

  tagStatesValueChanged() {
    this.selectedTogglesTarget.innerHTML = "";
    this.availableTogglesTarget.innerHTML = "";
    toPairs(this.tagStatesValue)
      .sort(([name1], [name2]) => name1.localeCompare(name2))
      .forEach(([name, value]) => {
        const tagToggleElement = cloneElement(this.toggleTemplateTarget.content.firstElementChild! as HTMLElement);
        tagToggleElement.dataset.tagToggleStateValue = value;
        tagToggleElement.dataset.tagToggleNameValue = name;
        if (value === "checked") {
          this.selectedTogglesTarget.appendChild(tagToggleElement);
        } else {
          this.availableTogglesTarget.appendChild(tagToggleElement);
        }
      });
    if (this.selectedTogglesTarget.children.length) {
      show([this.selectedGroupTarget]);
    } else {
      hide([this.selectedGroupTarget]);
    }
    if (this.availableTogglesTarget.children.length) {
      show([this.availableGroupTarget]);
    } else {
      hide([this.availableGroupTarget]);
    }
  }

  setTagValue(name: string, value: string) {
    const tagStatesValueCopy = { ...this.tagStatesValue };
    tagStatesValueCopy[name] = value;
    this.tagStatesValue = tagStatesValueCopy;
  }

  resetNewForm() {
    this.newInputTarget.value = "";
    this.newInputTarget.focus();
    this.updateNewForm();
  }
}
