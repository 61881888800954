import Cookies from "js-cookie";

export default function setTzCookie() {
  const cookieKey = "tz"; // must match ApplicationController#cookies["tz"]
  const defaultZone = "UTC";

  const zoneCookie = Cookies.get(cookieKey);
  if (!zoneCookie) {
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone || defaultZone;
    Cookies.set(cookieKey, zone, { expires: 1 });
  }
}
