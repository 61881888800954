import flatpickr from "flatpickr";
import { Target, Value } from "@vytant/stimulus-decorators";
import { Instance } from "flatpickr/dist/types/instance";
import ApplicationController from "./application_controller";

const dateFormat = "Y-m-d";

export default class DateRangeFilterController extends ApplicationController {
  @Target inputTarget!: HTMLInputElement;
  @Target containerTarget!: HTMLElement;
  @Value(String) dateRangeValue!: string;

  fp!: Instance;

  connect() {
    this.fp = flatpickr(this.containerTarget, {
      mode: "range",
      inline: true,
      dateFormat,
      onReady: (_dates, _dateStr, fp) => {
        fp.setDate(this.dates, false);
      },
      onChange: (dates) => {
        if (dates.length === 0) {
          // handle clear
          this.dateRangeValue = "";
        } else if (dates.length === 2) {
          // handle selected both dates
          this.dateRangeValue = `${this.fp.formatDate(dates[0], dateFormat)};${this.fp.formatDate(dates[1], dateFormat)}`;
        }
      },
    });
  }

  get dates() {
    return this.dateRangeValue.split(";");
  }

  dateRangeValueChanged() {
    this.inputTarget.value = this.dateRangeValue;
    this.inputTarget.disabled = this.dateRangeValue === "";
  }
}
