import { Extension } from "@tiptap/core";
import { liftTarget } from "@tiptap/pm/transform";
import { Selection } from "@tiptap/pm/state";

// https://tiptap.dev/guide/typescript
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    extraCommands: {
      preventHistory: () => ReturnType;
      joinPrevTextblock: () => ReturnType;
      absorbNextTextblock: () => ReturnType;
      liftToTop: () => ReturnType;
    };
  }
}
export default Extension.create({
  name: "extraCommands",
  addCommands: () => ({
    preventHistory:
      () =>
      ({ state, dispatch }) => {
        const { tr } = state;
        tr.setMeta("addToHistory", false);
        dispatch?.(tr);
        return true;
      },
    joinPrevTextblock:
      () =>
      ({ state, dispatch }) => {
        const { tr } = state;
        const { $anchor } = state.selection;
        let before = $anchor.before();
        let node;
        let textblock;
        while (before >= 0) {
          node = state.doc.resolve(before).parent;
          if (node.isTextblock) {
            textblock = node;
            break;
          }
          before -= 1;
        }
        if (!textblock) return false;
        if (dispatch) {
          tr.deleteRange(before, $anchor.before() + $anchor.parent.nodeSize);
          tr.insert(before, $anchor.parent.content);
          const selection = Selection.findFrom(tr.doc.resolve(before), 1);
          if (selection) {
            tr.setSelection(selection).scrollIntoView();
          }
          dispatch(tr);
        }
        return true;
      },
    absorbNextTextblock:
      () =>
      ({ state, dispatch }) => {
        const { tr } = state;
        const { $anchor } = state.selection;
        let after = $anchor.after();
        let node;
        let textblock;
        while (after <= state.doc.content.size) {
          node = state.doc.resolve(after).parent;
          if (node.isTextblock) {
            textblock = node;
            break;
          }
          after += 1;
        }
        if (!textblock) return false;
        if (dispatch) {
          tr.deleteRange($anchor.after(), after + textblock.content.size);
          tr.insert($anchor.pos, textblock.content);
          const selection = Selection.findFrom(tr.doc.resolve($anchor.pos), 1);
          if (selection) {
            tr.setSelection(selection).scrollIntoView();
          }
          dispatch(tr);
        }
        return true;
      },
    liftToTop:
      () =>
      ({ state, dispatch }) => {
        const { tr } = state;
        const { $anchor } = state.selection;
        const range = $anchor.blockRange();
        if (range) {
          const canLiftTo = liftTarget(range);
          if (canLiftTo !== 0) return false;
          if (dispatch) {
            tr.lift(range, canLiftTo);
            dispatch(tr);
          }
        }
        return true;
      },
  }),
});
